<template>
  <div>
    <park-info-header></park-info-header>
    <img class="contact" src="../../assets/img/park/contact.png" />
    <img class="welcome" src="../../assets/img/park/welcome.png" />
    <div class="centertext">
      <div class="title">江苏南通人力资源服务产业园</div>
      <div class="small-title">JIANGSU NANTONG HUMAN RESOURCES SERVICE INDUSTRIAL PARK</div>
      <div class="address">江苏省南通市崇川区工农北路150号</div>
      <div class="Telephone">客户服务中心电话：0513-83559096</div>
      <div class="email">邮箱：business@chinacdhr.com</div>
      <div class="Postalcode">邮编 ：226000</div>
      <div class="Fax">传真： 028-87570531</div>
    </div>
    <address-map></address-map>
    <park-info-footer></park-info-footer>
  </div>
</template>

<script>
import ParkInfoHeader from '@/components/ParkInfoHeader/index'
import ParkInfoFooter from '@/components/ParkInfoFooter/index'
import AddressMap from '@/components/AddressMap'

export default {
  name: 'ContactUs',
  components: {
    ParkInfoHeader,
    ParkInfoFooter,
    AddressMap
  }
}
</script>

<style lang="less" scoped>
// @font-face {
//   font-family: 'SourceHanSansCN';
//   font-style: normal;
//   src: url('~@/assets/font/SourceHanSansCN.otf');
// }

.contact {
  width: 375px;
  height: 130px;
}

.welcome {
  width: 107px;
  height: 30px;
  margin: 30px 141.5px 17.5px 126.5px;
}

.centertext {
  margin: 0px 28px 32px 28.5px;
  width: 318.5px;
  height: 177px;

  .title {
    margin: 0 auto;
    color: rgba(0, 0, 0, 1);
    font-weight: 700;
    font-family: 'SourceHanSansCN';
    font-size: 14.1px;
    text-align: center;
    line-height: 27px;
  }

  .small-title {
    margin: 0 auto;
    font-weight: 400;
    width: 200px;
    height: 5.5px;
    font-size: 5.4px;
    text-align: center;
    color: #666666;
  }

  .address {
    margin: 27.5px auto 0;
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    font-family: 'SourceHanSansCN';
    font-size: 14px;
    text-align: center;
    line-height: 27px;
  }

  .Telephone {
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    font-family: 'SourceHanSansCN';
    font-size: 14px;
    text-align: center;
    line-height: 27px;
  }

  .email {
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    font-family: 'SourceHanSansCN';
    font-size: 14px;
    text-align: center;
    line-height: 27px;
  }

  .Postalcode {
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    font-family: 'SourceHanSansCN';
    font-size: 14px;
    text-align: center;
    line-height: 27px;
  }

  .Fax {
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    font-family: 'SourceHanSansCN';
    font-size: 14px;
    text-align: center;
    line-height: 27px;
  }
}

.map {
  width: 375px;
  height: 395px;
  margin: 32px 0 0 0;
  background-size: 100% 100%;
}
</style>
